@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Vithkuqi&family=Freckle+Face&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;700&display=swap");

*,
*::before,
*::after {
  font-family: "Lexend", sans-serif;
}

/* Hide arrows in WebKit browsers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  height: 100%;
  margin: 0;
}

#nav-bar {
  box-shadow: -1px 2px 5px 0px #80808033 !important;
}

.loader {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 68%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.5s linear infinite;
}

.darkLoader {
  width: 14px;
  height: 14px;
  border: 2px solid #000000;
  border-bottom-color: transparent;
  border-radius: 68%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.5s linear infinite;
}

.approveWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: gray;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.alertComponent {
  position: fixed;
  z-index: 200;
  top: 10%;
  right: 0%;
  box-shadow: 0px 2px 7px #4c9051;
}

#successAlert {
  background-color: #bef5c2;
  color: green;
  border-top: 2px solid green;
}

#declineAlert {
  background-color: #ffe1e1;
  color: red;
  border-top: 2px solid red;
}

.box {
  width: 100%;
  height: 200px;
  background-color: #fff;
  margin: 100px auto;
  filter: url(#round);
  position: relative;
  rotate: 180deg;
  margin-top: -10px;
  border-top-right-radius: 30px;
}
.box::before,
.box::after {
  content: "";
  position: absolute;
  border-radius: 30px;
  width: 50%;
  height: 92%;
  bottom: 162px;
  background: inherit;
  left: -40px;
  right: 158px !important;
  background: inherit;
}
.box::after {
  right: -40px;
  left: auto;
}

/* ===================== Mission Section ============================== */

#mission-title {
  font-family: "Freckle Face";
  font-size: 48px;
  color: #216206;
  width: 244px;
}

.mission-section-wrapper > p {
  line-height: 27px;
}

/* ============ Footer ========================== */
.footer {
  background: #ccd1ca;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  /* padding: 62px; */
}

.quick-link {
  line-height: 30px;
  font-size: 14px;
}

#social-ul {
  display: flex;
  justify-content: space-between;
}

/* ================================================== */
.no-top {
  padding-top: 0;
}

.no-top {
  padding-top: 0;
}

/* Login Section */

.login-form {
  flex: 0 0 55%;
  font-family: "Lexend", sans-serif;
  padding: 16px;
}
.login-form h1 {
  font-family: "Freckle Face", sans-serif;
}
.login-form form {
  font-family: "Lexend", sans-serif;
}

.aside {
  box-shadow: 1px 0px 3px #dfdcdc;
}

.top-nav {
  box-shadow: 1px 0px 3px #dfdcdc;
}

#side-link-state {
  display: flex;
  align-items: center;
  color: rgb(102, 102, 102);
  padding: 1rem;
}

#side-link-state:hover {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
  background-color: #216206;
}

#side-link-state:hover path {
  fill: #fff;
}

/* .active {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff !important;
  background-color: #216206;
}

.active path {
  fill: #fff !important;
} */

/*================== Dashboard =========================== */
.content-section {
  background-color: #f1f5f9;
  margin-top: -10px !important;
  min-height: 100vh;
  padding-left: 0px !important;
  padding-right: 0 !important;
}
.dashboard-section {
  background-color: #f1f5f9;
  margin-top: -10px !important;
  min-height: 100vh;
  /* padding-left: 0px !important;
  padding-right: 0 !important; */
}
.reg-section {
  background-color: #f1f5f9;
  min-height: 100vh;
  padding-left: 0px !important;
  padding-right: 0 !important;
  line-height: 34px;
}

#dashboard-avatar {
  width: 60%;
}

#dashboard-logo {
  width: 60%;
}

#single-logo {
  width: 4%;
}

.nav-hover {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: rgb(107, 107, 107);
}

/* .nav-hover:hover {
  color: #fff;
}

.nav-hover:hover path {
  fill: #fff;
} */

#single-logo {
  width: 4%;
}

.weather-card {
  background-color: #e3f3e3;
  border-radius: 20px;
}

.chart-card-wrapper {
  box-shadow: 0px 4px 9px 0px #808080a8;
  padding: 15px;
  font-size: 14px;
  color: #fff;
  padding-bottom: 64px;
}

.chart-card-one {
  background-color: #b88c19;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin-top: -61px;
  padding: 0 10px;
}

.chart-card-two {
  background-color: #4caf50;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin-top: -61px;
  padding: 0 10px;
}

/* =========== Produce Listings ============================= */

.add-to-list {
  width: 130px;
  height: 35px;
  border-radius: 5px;
}

#add-btn {
  background-color: #216206;
  color: #fff;
}

#sort-btn {
  border: 1px solid #216206;
  color: #216206;
  background-color: transparent;
}

.selected-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

#selected-btn {
  all: unset;
  width: 70px !important;
  border: 1px solid #216206;
  color: #216206;
  background-color: transparent;
  position: relative;
  padding: 0 8px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

/* #cancle{
  position: absolute;
  top: 5px;
  right: 5px;
} */

/* ============ Produce Form ============================ */
.produce-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.produce-form {
  width: 70%;
  background-color: #fff;
  box-shadow: 0px 0 3px 0px rgb(170, 170, 170);
  padding: 40px;
}

.produce-input {
  /* border: .5px solid rgb(185, 185, 185); */
  border-radius: 5px;
  color: rgb(73, 72, 72);
}

.produce-input:focus {
  outline: unset !important;
}

.produce-input::placeholder {
  font-weight: 100;
}

/* ================================= */

.option-btn-wrapper {
  margin: 10px;
  display: flex;
  justify-content: right;
}

.mobile-tab {
  display: block !important;
}

.side-btn {
  position: fixed;
  bottom: 50%;
  margin: 0;
  padding: 10px;
  transition: transform 0.3s ease-in-out;
}

.side-btn:hover .rot-up {
  transform: rotate(30deg);
}

.side-btn:hover .rot-down {
  transform: rotate(-30deg);
}

/* ====================================================
=========================================================== */
.side-link {
  padding: 10px 20px;
  box-shadow: 0 0 1px 0px rgb(148, 148, 148);
  text-decoration: none;
  color: #5d657b;
  font-weight: 500;
  width: 100%;
}

.side-link:hover {
  color: #282b33;
}

.subLink-wrapper {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease;
  transform-origin: top;
  padding-left: 0;
}

.side-link.open + .subLink-wrapper {
  max-height: 300px; /* Adjust the value based on your content height */
  transition: max-height 0.5s ease-in;
  transform-origin: top;
}

.side-icon {
  font-size: 18px;
}

.side-nav-sublink {
  text-decoration: none;
  color: rgb(112, 112, 112);
  font-size: 14px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.side-nav-sublink > a {
  text-decoration: none;
  color: rgb(90, 90, 90);
}

.side-nav-sublink:hover {
  background-color: rgba(94, 146, 206, 0.089);
}

.side-nav-sublink:hover > a {
  color: rgb(94, 146, 206);
}

/* .active {
  background-color: rgb(2, 92, 6);
} */

#total-balance {
  background-color: #b88c19;
}

#account-details {
  background-color: #4caf50;
}

#balance {
  color: #e8fb92;
  font-size: 40px;
}

.account-info {
  color: #0c600c;
  font-size: 18px;
  font-weight: 500;
}

.dashboard-label {
  font-size: 18px;
  color: rgb(104, 103, 103);
}

table {
  background-color: #fff;
}

thead {
  background-color: #000000a3;
  color: #fff;
}

tr:nth-child(even) {
  background-color: #ddd7d7;
}

tr#receipt-row {
  background-color: transparent;
  border-bottom: 1px solid rgb(221, 218, 218);
}

.transfer-balance {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  margin-bottom: 30px;
  background-color: #b88c19;
  color: #fff;
  padding: 20px;
}

.eye-drop {
  font-size: 25px;
  list-style: none;
  cursor: pointer;
}

.chargesContainer {
  display: flex;
  justify-content: right;
}

.chargeWrapper {
  list-style: none;
  display: flex;
  font-size: 14px;
  color: gray;
}

.receipt-btn {
  padding: 8px;
  border-radius: 5px;
  background-color: transparent;
  color: rgb(66, 125, 253);
  border: 1px solid rgb(66, 125, 253);
}

.receipt-btn:hover {
  background-color: rgb(66, 125, 253);
  color: #fff;
}

.success-icon {
  font-size: 20px;
}

.cheque-input {
  height: 200px;
  padding: 20px;
  border: 1px solid rgb(177, 178, 182);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-cheque-btn {
  border: 1px solid gray;
  padding: 8px;
  border-radius: 8px;
  color: rgb(92, 92, 92);
  font-size: 15px;
  cursor: pointer;
}

.upload-cheque-btn:hover {
  background-color: rgb(102, 103, 105);
  color: #fff;
}

#deposit-cheque-btn {
  background-color: #0d6efd;
  color: #fff;
  border: none;
}

.badge {
  padding: 4px;
  color: #fff;
  border-radius: 2px;
  font-size: 11px;
}

#pending {
  background-color: orange;
}

#approved {
  background-color: #216206;
}

#danger {
  background-color: rgb(236, 61, 61);
}

.card {
  border: none;
  color: #fff;
  width: 90%;
  height: 240px;
  box-shadow: 8px 6px 7px 0px rgb(170, 170, 170);
}

.cryptoWrapper {
  display: flex;
  align-items: center;
}

.cryptoAddress {
  background-color: rgba(128, 128, 128, 0.295);
  color: #504d4d;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px 20px;
}

.cryptoAddress:focus {
  outline: none !important;
}

.copyBtn {
  background-color: orange;
  padding: 9px 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.copyBtn:hover {
  background-color: rgb(255, 136, 0);
}

.profileBox {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  /* background-color: #216206;  */
  position: relative;
  /* outline: 1px solid #bdbbbb; */
}

.avatarInputIcon {
  background-color: #fff;
  padding: 6px 10px;
  border-radius: 100%;
  box-shadow: 1px 1px 4px 1px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
}

.hero {
  width: 100%;
  height: 500px;
  background-image: url("./assets/hero.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroBtnWrapper {
  width: 30%;
  display: flex;
  justify-content: space-between;
  margin-top: 200px;
}

.signUp {
  /* width: 200px; */
  padding: 10px 60px;
  background-color: rgb(214, 141, 4);
  color: #fff;
  font-weight: 400;
  box-shadow: 1px 1px 3px 1px #3c3b3b;
}

.login {
  /* width: 200px; */
  padding: 10px 60px;
  background-color: #0c600c;
  color: #fff;
  font-weight: 400;
  box-shadow: 1px 1px 3px 1px #3c3b3b;
}

.partners {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.receipt-data {
  color: rgb(66, 125, 253);
}

.active {
  background-color: #0c600c;
  /* padding: 10px; */
  text-decoration: none;
  color: #fff !important;
  /* border-radius: 6px; */
}

.transparent-div {
  width: 100%;
  min-height: 100vh;
  /* background-color: rgba(29, 28, 28, 0.068); */
  position: fixed;
  z-index: 2;
}

#content-pad {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.wrapper {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.otp {
  width: 60%;
  background-color: #a9a9a98f;
  margin: 10px;
}

#otp-wrapper {
  display: flex;
  justify-content: center;
}

.rat {
  background-color: #a9a9a98f;
  width: 48px !important;
  height: 48px !important;
  border: none;
}

.otp-resend{
  flex-direction: column !important;
  align-items: flex-start !important;
}

.otp-resend button {
  color: orangered !important;
}

.ot-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
}

.updateWrapper{
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.kyc {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}


#depositwrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* width: 100%; */
}

#suspended{
  background-color: orange;
}

.pictureHolder{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-color: white;
}

.statusWrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.roleButton{
  padding: 10px 20px;
  margin-right: 10px;
  background-color: white;
}

.roleButton:first-child{
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.roleButton:last-child{
  margin-right: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* 
=============================================================
New
=============================================================
 */

/* =================== Mobile breakpoint =========================== */
@media screen and (max-width: 768px) {
  .produce-form {
    width: 100%;
    padding: 20px;
  }

  .produce-form-wrapper {
    padding: 10px !important;
  }

  .content-section {
    padding: 1rem 0;
  }

  .heroBtnWrapper {
    width: 90%;
    /* margin: 0 10px; */
  }

  .dashboard-section {
    background-color: #f1f5f9;
    margin-top: -10px !important;
    min-height: 100vh;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .atmWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .wrapper {
    width: 100%;
  }

  .otp {
    width: 100%;
  }

  .rat{
    margin-right: 10px !important;
    width: 40px !important;
    height: 40px !important;
  }
}
